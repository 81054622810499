
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'alert',
  props: {
    message: {
      type: String,
      required: true,
    },
    noMargin: {
      type: Boolean,
      default: false,
    }
  },
});
