
import {defineComponent} from "vue";
import { IonSpinner } from "@ionic/vue";

export default defineComponent({
  name: 'Loading',
  components: {
    IonSpinner,
  },
});
