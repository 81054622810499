<template>
  <Container @secondary-click="toggleFavorites" back-button="/" :name="t('information.title')" :secondary-button="favoritesButtonIcon" :secondary-button-label="t('information.onlyFavorite')">
    <Loading v-show="loading" />
    <IonGrid v-if="items" class="ion-no-padding">
      <IonRow>
        <IonCol :size="12">
          <IonCard>
            <IonCardContent class="information-card">
              <Alert v-if="filteredItems.length === 0" :message="t('information.noItems')" />
              <section v-for="category in filteredItems" :key="category.category" class="category">
                <div @click="toggleCategory(category.category)" class="header">
                  <IonItem>
                    <IonLabel class="category-header">{{ category.category }}</IonLabel>
                    <IonIcon :icon="categoryIcon(category.category)" size="small" slot="end" aria-hidden="true"></IonIcon>
                  </IonItem>
                </div>
                <div v-if="! categoriesToHide.includes(category.category)">
                  <IonList>
                    <IonItem
                        class="category-item ion-padding-start"
                        v-for="(info, k) in category.items"
                        :key="`${category.category}-${info.id}`"
                        @click="router.push(`/information/${info.id}`)"
                        :lines="k === category.items.length - 1 ? 'none' : ''"
                    >
                      <IonIcon :icon="!info.completed ? ellipseOutline : checkmarkCircleOutline" slot="start" :color="info.completed ? 'success' : 'primary'" :aria-label="!info.completed ? t('information.read') : t('information.unread')" />
                      <IonLabel class="category-item-label">{{ info.title }}</IonLabel>
                      <IonIcon :icon="info.favorite ? star : chevronForwardOutline" :class="{'color-gold': info.favorite}" size="small" slot="end" aria-hidden="true"></IonIcon>
                    </IonItem>
                  </IonList>
                </div>
              </section>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  </Container>
</template>

<script type="ts">
import {defineComponent} from 'vue';
import {
  IonCard,
  IonCardContent,
  IonLabel,
  IonItem,
  IonList,
  IonRow,
  IonGrid,
  IonCol,
  IonIcon,
} from "@ionic/vue";
import {
  chevronDownOutline,
  chevronUpOutline,
  chevronForwardOutline,
  checkmarkOutline,
  ellipseOutline,
  checkmarkCircleOutline,
  starOutline,
  star,
} from 'ionicons/icons'
import {mapGetters, mapActions} from 'vuex';
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n';
import Container from "../layout/Container.vue";
import {isPlatform} from "@ionic/vue";
import Loading from "@/components/Loading.vue";
import mixin from "@/mixin";
import Alert from "@/components/Alert.vue";

export default defineComponent({
  name: 'Information',
  components: {
    Alert,
    Loading,
    Container,
    IonCard,
    IonCardContent,
    IonLabel,
    IonItem,
    IonList,
    IonRow,
    IonGrid,
    IonIcon,
    IonCol,
  },
  setup() {
    const router = useRouter();
    const {t} = useI18n();

    return {
      router,
      t,
      chevronDownOutline,
      chevronUpOutline,
      star,
      chevronForwardOutline,
      checkmarkOutline,
      ellipseOutline,
      checkmarkCircleOutline,
    }
  },

  mixins: [
    mixin
  ],

  data() {
    return {
      loading: false,
      showOnlyFavorites: false,
    };
  },

  computed: {
    ...mapGetters({
      items: 'information/items',
      categoriesToHide: 'information/categoriesToHide'
    }),
    filteredItems() {
      return this.items.map(category => {
        const items = ! this.showOnlyFavorites ? category.items : category.items.filter(item => {
          return item.favorite;
        });

        return {
          ...category,
          items,
        }
      });
    },
    favoritesButtonIcon() {
      return this.showOnlyFavorites ? star : starOutline;
    }
  },
  methods: {
    ...mapActions({
      fetchItems: 'information/fetchItems',
      toggleCategory: 'information/toggleCategory'
    }),

    ionViewWillEnter() {
      this.init();
    },

    categoryIcon(category) {
      return this.categoriesToHide.includes(category) ? chevronDownOutline : chevronUpOutline
    },

    async init() {
      this.loading = true;
      await this.fetchItems(true);
      this.loading = false;
    },

    toggleFavorites() {
      this.showOnlyFavorites = ! this.showOnlyFavorites;
    }
  }
});
</script>

<style scoped lang="scss">
.information-card {
  padding: 0;

  .category-header {
    font-weight: bold;
    font-size: 1.1rem;
  }

  .header {
    ion-item {
      --ion-background-color: #f1f1f1;
    }
  }

  .category-item {
    padding: 0 0 0 10px;

    ion-icon {
      margin-right: 7.5px;
    }

    ion-label {
      font-size: 1rem;
      margin-left: 0;
      padding-left: 0;
    }
  }

  ion-label:first-child {
    padding: 0 15px;
  }

  ion-checkbox {
    margin-left: 15px;
  }

  ion-item {
    --padding-start: 0;
  }

  .color-gold {
    color: gold;
  }
}
</style>
