
import {
  IonPage,
  IonToolbar,
  IonContent,
  IonHeader,
  IonTitle,
  IonIcon,
  IonBackButton,
  isPlatform
} from '@ionic/vue'
import {useRouter} from 'vue-router';
import {defineComponent} from "vue";
import { homeSharp, cogOutline, arrowBackOutline, settingsSharp } from 'ionicons/icons';
import Button from "@/components/Button.vue";
import {mapGetters} from "vuex";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: 'Container',
  components: {Button, IonPage, IonContent, IonTitle, IonHeader, IonToolbar, IonIcon, IonBackButton },
  setup() {
    const router = useRouter();
    const {t} = useI18n()

    return {
      t,
      router,
      homeSharp,
      settingsSharp,
      cogOutline,
      arrowBackOutline,
      isPlatform
    };
  },

  computed: {
    cssVars(): object {
      return {
        '--ion-background-color': this.themeColors.backgroundColor,
        '--ion-color-primary': this.themeColors.primaryColor,
        '--ion-color-secondary': this.themeColors.secondaryColor,
      };
    },

    bgVars(): object {
      return this.themeColors.background ? {
        'background': `url(${this.themeColors.background}) no-repeat bottom center`,
        'background-size': 'cover'
      } : {};
    },

    contentVars(): object {
      return this.themeColors.background ? {
        '--ion-background-color': this.themeColors.primaryBackgroundColor
      } : {};
    },

    ...mapGetters("permission", ["getPermission"]),
    ...mapGetters({
      theme: 'app/theme',
      themeColors: 'app/themeColors',
    }),
    isDashboard(): boolean {
      return this.$route.name === 'dashboard';
    },
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    backButton: {
      type: String,
      default: null,
    },
    settingsButton: {
      type: Boolean,
      default: false,
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    secondaryButton: String,
    secondaryButtonLabel: String,
  },
});
