import {createGesture, Gesture} from "@ionic/vue";
import {defineComponent} from "vue";

const mixin = defineComponent({
    data() {
        return {
            gesture: null as Gesture|null
        }
    },

    methods: {
        ionViewWillLeave() {
            /*
            if (this.gesture === null) {
                this.gesture = createGesture({
                    el: document.getElementById('main-content'),
                    threshold: 0,
                    gestureName: 'swipe-override',
                    gesturePriority: 40.5,
                } as any);
            }

            this.gesture?.enable(true);
             */
        },

        ionViewDidLeave() {
            /*
            this.gesture?.enable(false);
             */
        }
    }
});

export default mixin;
