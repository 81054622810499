import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonBackButton = _resolveComponent("IonBackButton")!
  const _component_IonTitle = _resolveComponent("IonTitle")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_IonToolbar = _resolveComponent("IonToolbar")!
  const _component_IonHeader = _resolveComponent("IonHeader")!
  const _component_IonContent = _resolveComponent("IonContent")!
  const _component_IonPage = _resolveComponent("IonPage")!

  return (_openBlock(), _createBlock(_component_IonPage, {
    style: _normalizeStyle(_ctx.cssVars)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_IonHeader, { class: "ion-no-border" }, {
        default: _withCtx(() => [
          _createVNode(_component_IonToolbar, { color: "secondary" }, {
            default: _withCtx(() => [
              (_ctx.backButton && !_ctx.isPlatform('android'))
                ? (_openBlock(), _createBlock(_component_IonBackButton, {
                    key: 0,
                    class: "back-button",
                    slot: "start",
                    text: _ctx.t('back'),
                    defaultHref: "/"
                  }, null, 8, ["text"]))
                : _createCommentVNode("", true),
              (_ctx.isDashboard)
                ? (_openBlock(), _createBlock(_component_IonTitle, {
                    key: 1,
                    class: "containerTitle",
                    slot: !_ctx.backButton || _ctx.isPlatform('android') ? 'start' : ''
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getPermission('user.full_name')), 1)
                    ]),
                    _: 1
                  }, 8, ["slot"]))
                : (_openBlock(), _createBlock(_component_IonTitle, {
                    key: 2,
                    class: "containerTitle",
                    slot: !_ctx.backButton || _ctx.isPlatform('android') ? 'start' : ''
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.name), 1)
                    ]),
                    _: 1
                  }, 8, ["slot"])),
              (_ctx.settingsButton)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 3,
                    slot: "end",
                    color: "secondary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/settings'))),
                    class: "settings-button"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_IonIcon, { icon: _ctx.settingsSharp }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.secondaryButton)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 4,
                    slot: "end",
                    color: "secondary",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('secondary-click'))),
                    class: "settings-button",
                    "aria-label": _ctx.secondaryButtonLabel
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_IonIcon, { icon: _ctx.secondaryButton }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["aria-label"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_IonContent, {
        id: "content",
        style: _normalizeStyle(_ctx.contentVars)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(`bg active ${_ctx.theme}`),
            style: _normalizeStyle(_ctx.bgVars)
          }, null, 6),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["style"]),
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["style"]))
}